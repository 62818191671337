@import "mixin";

html{
    font-size: 62.5%;
}

body{
    font-weight: 400;
    font-family: YakuHanJP, '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: .07em;
    position: relative;
    color: #000;
    line-height: 1.6;
    font-size:1.5rem;
}

body > header{
}

body > footer{
    p{
      color:white;
      font-size: 1.6rem;
    }
    .b03{
      background-image: url(../images/p03.jpg);
    }
    .tel{
      display:table;
      max-width: 454.5px;
      width:100%;
      margin:30px auto 0;
    }
    .form{
      max-width:462.5px;
      display:block;
      margin:30px auto 0;
    }
    .bdr{
      max-width: 50px;
      margin:50px auto;
      border-top: 1px solid white;
    }
    small{
      display:block;
      text-align:center;
      color:white;
    }
}