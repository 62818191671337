#page-company{
  .title{
    font-size:1.7em;
    color:#615553;
    font-weight:600;
    margin-bottom: 1em;
    padding-bottom: .5em;
    border-bottom: solid 1px #615553;
  }
  table{
    font-size: 1.6rem;
    width:100%;
    th,td{
      padding:1.5em;
      vertical-align: top;
    }
    tr{
      &:not(:last-child){
        th,td{
          border-bottom: solid 1px #ccc;
        }
      }
    }
    th{
      width:15em;
      font-weight: 500;
    }
    td{

    }
  }
  .name{
    img{
      height: 11.5px;
    }
  }
  p{
    &:nth-of-type(1){
      margin-top: .5em;
      font-size: 2rem;
    }
    &:nth-of-type(2){
      font-weight:600;
    }
  }
  .staff{
    &:not(:first-child){
      margin-top:60px;
    }
  }
}
