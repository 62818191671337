@import "site-variables";

.container{
    max-width: 1000px !important;
}

.site-container{
  width: 970px;
  margin:{
    left: auto;
    right: auto;
  }
}

#global-header{

}

.content-header{
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
    padding:80px 15px;
    img{
        &:nth-of-type(1){
            max-height: 29px;
            display:table;
            margin:0 auto;
        }
    }
    .iw{
        position:absolute;
        bottom:-80px;
        left:0%;
        text-align: center;
        width:100%;
        img{
            max-height: 30px;
            display:table;
            margin:0 auto;
            transform:translateY(50%);
        }
    }
}
.bg-b{
    background-color: $b;
}
.pdng{
    padding:{
        top:80px;
        bottom:80px;
    }
}

.bb-head{
    background-color: #615553;
    padding-top: 30px;
    padding-bottom: 30px;
    .bb-in{
        img{
            height: 25.5px;
        }
    }
}

.obj{
    background-image: url(../images/obj.png);
    background-repeat: no-repeat;
    background-position: right top;
}
