#page-contact{
  .title{
    font-size:1.7em;
    color:#615553;
    font-weight:600;
    margin-bottom: 1em;
    padding-bottom: .5em;
    border-bottom: solid 1px #615553;
  }
  table{
    font-size: 1.6rem;
    margin: 0 auto;
    th,td{
      padding:1.5em;
      vertical-align: top;
    }
    tr{
      &:not(:last-child){
        th,td{
          border-bottom: solid 1px #ccc;
        }
      }
    }
    th{
      width:15em;
      font-weight: 500;
    }
    td{

    }
  }
}
