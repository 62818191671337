#page-service{
    .service-c{
        margin-top: 60px;
        p{
            margin-top: 3em;
            font-size: 1.6rem;
        }
    }
    .sh01{
        max-width:310px;
    }
    .sh02{
        max-width:206px;
    }
    .sh03{
        max-width:206px;
    }
    .sh04{
        max-width:294px;
    }
}
