#page-index{
    #m{
        width:100%;
        height: calc(100vh -73px);
        background-color:$b;
        background-image:url(../images/m-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @include max($break-mobile){
            height: calc(100vh -52px);
        }
    }
    #mt-bg{
        display:table;
        background-image: url(../images/bg-c.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 50px;
        .bg{
            background-image: url(../images/m-bg-i.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding: 130px 0;
        }
        color:white;
        span{
            visibility: visible;
            opacity: 1;
            animation:anim2 1.5s ease-in 1.5s both;
            &.ttt{
                display:block;
                margin-bottom:.8em;
            }
            &.bbb{
                margin-top: .5em;
                font-size: 2.4rem;
            }
        }
    }
    #mt{
        text-align: center;
        width:100%;
        max-width: 384px;
        svg{
            stroke:#FFF;
            stroke-width:3px;
            fill:transparent;
            stroke-dasharray:1425px;
            stroke-dashoffset:1425px;
            animation:anim 2.5s ease-in 1s forwards;
        }
    }
    .b01{
        background-image:url(../images/p01.jpg);
    }
    .b02{
        background-image:url(../images/p02.jpg);
    }
    .pd{
        padding:30px;
    }
    .ai{
        color:white;
        font-size: 1.6rem;
        line-height:2;
    }
    .tit{
        img{
            height:36.5px;
        }
        position:absolute;
        z-index: 2;
        transform: translateY(-70%);
    }
    .ser{
        >div{
            position: relative;
            margin:0 30px;
        }
        .link-t{
            display:block;
            margin-top: 1em;
            margin-bottom:2em;
            color:white;
            text-align:center;
            font-weight: 600;
        }
    }
    .bbb{
        padding-top: 30px;
    }
}

@keyframes anim{
    0%{stroke-dashoffset:1425px;}
    30%{fill:transparent;}
    60%{stroke-dashoffset:0;}
    100%{stroke-dashoffset:0; fill:#FFF;}
}

@keyframes anim2{
    0%{
        visibility:visible;
        opacity:0;
    }
    100%{
        opacity:1;
    }
}
