@import "mixin";
@import "media";

#pc-head{
  background-color:$b;
  padding:{
    top:20px;
    bottom:20px;
  }
  .menu-btn{
    display:none;
  }
  .hamburger{
    padding:0 !important;
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
      background-color: #fff;
    }
  }
  @include max($break-mobile){
    padding:{
      top:0px;
      bottom:0px;
    }
    position:relative;
    .menu-btn{
      display:block;
      position:absolute;
      right: 15px;
      top:15px;
      z-index: 3;
    }
  }
  .logo{
    margin:0 auto;
    display:table;
    background-color:$b;
    img{
        height: 30.5px;
    }
    @include max($break-mobile){
      display:block;
      text-align: center;
      position:relative;
      z-index: 2;
      padding:{
        top:10px;
        bottom:10px;
      }
    }
  }
  ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    @include max($break-mobile){
      display:block !important;
      position:absolute;
      width:100%;
      z-index: 1;
      transform: translateY(-100%);
      transition: .5s;
      &.active{
        transform: translateY(0%);
      }
      li{
          width:100%;
          text-align:center;
          margin-left: 0px;
          margin-bottom: 10px;
      }
    }
    li{
        margin-left: 15px;
        margin-bottom: 0;
    }
    img{
      height:11.5px;
    }
  }
}
